.invitations{
    .invite-sections{
        background-color: darken($white, 10);
        padding: 10px;
        margin-top: -96px;
        z-index: 1;
        position: relative;
    }
    .invite-top-section{
        background-color: darken($white, 15);
        padding: 10px 25px;
        border-radius: 10px;
    }
    .sep-line{
        height: 2px;
        margin: 10px 0 20px;
        width: 100%;
        background-color:  darken($white, 15);

    }
    .btn-green{
        width: 240px;
        box-shadow: none;
        margin: 10px auto;
        border-radius: 6px;
        display:  block !important;
        img{
            width: 24px;
            margin-right: 5px;
        }
    }
    .btn{
        border-radius: 6px;
        display: inline-block;
    }
    h4{
        font-size: 1rem;
        font-weight: 700;
        color: $black;

    }
    .table{
        td{
            padding: 4px;
            img{
                width: 80%;
                display: block;
                margin: auto;
                height: 180px;
            }
            .btn{
                margin-top: 20px;
            }
        }
    }


    .invite-art{
        background: url('../../images/invite-art.svg') no-repeat;
        background-position: center;
        background-size: 85%;
    }

    .invite-bottom-section{
        background-color: $white;
        padding: 10px 25px;
        border-radius: 10px;
        margin-top: 10px;

        tr{
            border-bottom: 1px solid darken($white, 10);
            td{
                border: none;
                padding: 8px;
                &:first-child{
                    width: 60%;
                }

                .revoke{
                    color: $red;
                }
                .resend{
                    color: $primary-color;
                }
            }
        }
    }


    .invitation-header{
        background-color: lighten($black, 25);
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        position: relative;
        font-weight: 700;
        color: $white;
        padding: 15px 15px;
        margin: -10px -25px 10px;
        text-transform: uppercase;
        .btn{
            margin-right: 10px;
            box-shadow: none;
            &.btn-primary{
                border: 2px solid lighten($black, 60) !important;
                color: lighten($black, 60) !important;
                padding: 6px 20px !important;
            }
        }
    }
    #invite-search{
        padding: 5px 35px 5px 20px;
        width: 100%;
        font-size: 1rem;
        border: none;
        height: 42px;
        border-radius:6px;
        background: url('../../images/search-grey.svg') no-repeat lighten($black, 95);
        background-position: right 20px center;
        background-size: 1rem;
        color: $black;
        outline: 0;
        box-shadow: none
      }
    ul{
        &.list-inline{
            li{
                display: inline-block;
                margin-right: 5px;
            }
        }
    }
}