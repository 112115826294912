.manage--files{

   .scroll-area {
    height: calc(100vh - 145px);
    position: relative;
    border-radius: 10px;
    }

    select {
        padding: 5px 35px 5px 20px;
        width: 100%;
        font-size: 1rem;
        border: none;
        height: 42px;
        border-radius: 6px;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        background: url('../../images/select-down-white.svg') no-repeat lighten($black, 18);
        background-position: right 20px center;
        background-size: 1rem;
        color: $white;
        outline: 0;
        &.page-count{
            width: 85px;
        }
      }
      input[type='search']{
        padding: 5px 35px 5px 20px;
        width: 100%;
        font-size: 1rem;
        border: none;
        height: 42px;
        border-radius: 6px;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        background: url('../../images/search.svg') no-repeat lighten($black, 18);
        background-position: right 20px center;
        background-size: 1rem;
        color: $white;
        outline: 0;
      }
      .manage-users-table{
          padding: 15px 30px;
          margin-top: -105px;

          .table{
            margin-bottom: 0;
              thead{
                  &:first-child{
                    tr{  
                        background-color: $white;
                        &.active-row{
                            background-color: lighten($sidebar-bg, 88)
                        }
                        th{
                            background-color: $default;
                            padding: 0.8rem;
                            text-align: center;
                            border:none;
                            color: $white;
                            width: 16.66%;
                            &:nth-child(even){
                              background-color: lighten($default, 10);
                            }
                            &:first-child{
                              border-top-left-radius: 10px;
                            }
                            &:last-child{
                              border-top-right-radius: 10px;
                            }                     
                        }
                    }
                  }
                  &:nth-child(2){
                    tr{  
                        background-color: $white;
                        th{
                            background-color: $black;
                            padding: 0.2rem;
                            text-align: center;
                            border:none;
                            color: $white;
                            font-weight: 400;
                            &:nth-child(odd){
                              background-color: lighten($black, 10);
                            }               
                        }
                     }
                  }
              }
              tbody{
                  tr{
                      td{
                        &:nth-child(even){
                            background-color: darken($white, 3);
                        }
                        &:nth-child(odd){
                            background-color: $white;
                        }
                        .file-container{
                          padding: 10px;
                          border: 1px solid darken($white, 30);
                          border-radius: 6px;
                          display: flex;
                          align-items: center;
                          background-color: $white;
                          margin-bottom: 10px;
                          position: relative;
                          .file-type{
                            img{
                              margin-right: 5px;
                            }
                          }

                          .dropleft{
                            position: absolute;
                            right: -5px;
                            top: 10px;
                            img{
                              width: 16px;
                            }
                            .dropdown-toggle::before{
                              display: none;
                            }
                          }
                          img{
                            width: 64px;
                            margin-right: 10px;
                          }
                          h4{
                            font-size: 14px;
                            font-weight: 700;
                            margin-top: 5px;
                            margin-bottom: 0;
                            width: 140px;
                            white-space: nowrap;
                            overflow: hidden;
                            text-overflow: ellipsis;
                          }
                          p{
                            margin-bottom: 0;
                            color: darken($white, 40);
                          }
                          small{
                            color: $primary-color;
                            display: block;
                            margin-top: 5px;
                          }
                          .dropdown-menu{
                            box-shadow: 0 0 10px darken($white, 20)
                          }
                        }
                      }
                      &:last-child{
                        td{
                            &:first-child{
                              border-bottom-left-radius: 10px;
                            }
                            &:last-child{
                              border-bottom-right-radius: 10px;
                            }                     
                        }
                      }
                  }
              }
          }
      }

    .container-fluid{
        &.no-padding{
            padding: 0;
        }
    }
}