@charset 'UTF-8';


// Dependancies
@import '~bootstrap/scss/bootstrap.scss';
@import '~perfect-scrollbar/css/perfect-scrollbar.css';
@import '~rc-calendar/assets/index.css';

// styles.scss
@import 'base/module';
@import 'components/module';
@import 'layout/module';
