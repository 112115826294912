.imgsize{
  width:150px;
  height:100px;
  }
  .imgsize2{
    width: 30px;
      position: absolute;
      top: 0px;
      left: 140px;
  }
  .custom-switch {
    padding-left: 2.25rem;
    padding-bottom: 1rem; // added for positioning
  }
  .custom-control-label { // added for alignment with the switch
    padding-top: 0.5rem;
    padding-left: 2rem;
    padding-bottom: 0.1rem;
  }
  .custom-switch .custom-control-label::before {
    left: -2.25rem;
    height: 2rem;
    width: 5.5rem;    // it was 1.75rem before. Sliding way is longer than before.
    pointer-events: all;
    border-radius: 1rem;
  }
  .custom-switch .custom-control-label::after {
    top: calc(0.25rem + 2px);
    left: calc(-2.25rem + 2px);
    width: calc(2rem - 4px);   // it was calc(1rem - 4px) before. Oval is bigger than before.
    height: calc(2rem - 4px);  // it was calc(1rem - 4px) before. Oval is bigger than before.
    background-color: #adb5bd;
    border-radius: 2rem; //   it was 0.5rem before. Oval is bigger than before.
    transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-transform 0.15s ease-in-out;
    transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: transform 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-transform 0.15s ease-in-out;
  }
  @media (prefers-reduced-motion: reduce) {
    .custom-switch .custom-control-label::after {
      transition: none;
    }
  }
  .custom-switch .custom-control-input:checked ~ .custom-control-label::after {
    background-color: #fff;
    -webkit-transform: translateX(1.5rem); //translateX(0.75rem);
    transform: translateX(3.5rem); //translateX(0.75rem);
  }
  .integrationForm label > span {
    float: left;
  }
  .container {
    position: relative;
    text-align: center;
    color: white;
  }
  .centered {
    position: relative;
    top: 100%;
    left: 50%;
    transform: translate(-50%, -50%);
    margin-top:5px;
    color:red;
    font-weight: 100;
  }
    
  .form-group label.switch-name {
    cursor: pointer;
    font-weight: normal;
  }
  .form-group .on {
    position: absolute;
      left: -1px;
      top: 10px;
      color: #000;
      transition: all 200ms ease;
  }
  .form-group .off {
      left: -30px;
      top: 10px;
      color: #fff;
      transition: all 200ms ease;
      position: absolute;
  }

  .tokenlable {     
    float: left;
    margin-left: 30px;
    margin-top: 3px; }
  .messagetextarea{
    height: 150px !important;
  }