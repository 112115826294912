#dashboard{
    header{
        position: fixed;
        width: 100%;
        top: 0;
        left: 0;
        right: 0;
        z-index: 100;
        background-color: $white;
            .boomea-logo{
                width: 225px;
                padding: 8px 15px 8px 0;
                float: left;
                img{
                    width: 100%;
                    max-width: 180px;
                    display: block;
                    margin: auto;
                }
            }
        .menu-icon{
            padding: 15px 20px;
            border-left: 1px solid lighten($black, 90);
            border-right: 1px solid lighten($black, 90);
            align-content: center;
            cursor: pointer;
            float: left;
            img{
                max-width: 100%;
                min-width: 24px;
            }
            &:hover{
                background-color: lighten($black, 98);
            }
        }
        .welcome-text{
            padding: 0px 15px;
            float: left;
        }
        .btn-primary{
            float: right;
        }
        .avatar-section{
            text-align: right;
            h4{
                font-weight: 700;
                font-size: 1rem;
                margin: 6px 0 5px;
                color: $dark-gray;
            }

            .avatar{
                margin-top: 4px;
            }
            .status{
                font-size: 0.8rem;
                .badge{
                    width: 8px;
                    height: 8px;
                    display: inline-block;
                    background-color: $light-gray;
    
                }
                &.online{
                    color: $green;
                    .badge{
                        background-color: $green;
                    }
                }
                &.offline{
                    color: #808080;
                    .badge{
                        background-color: #808080;
                    }
                }
                &.away{
                    color: rgb(255, 188, 66);
                    .badge{
                        background-color: rgb(255, 188, 66);
                    }
                }
                &.dnd{
                    color: #f74343;
                    .badge{
                        background-color: #f74343;
                    }
                }
            }

        }
    }
    .dropdown-toggle::after{
        display: none;
    }
    .btn-primary{
        padding: 0;
        background-color: transparent !important;
        border: none;
        outline: 0 !important;
        box-shadow: none;
        text-align: right;
    }
    .dropdown{
        text-align: right;
    }
    .dropdown-menu{
        top: 4px !important;
        border-radius: 0;
        padding: 0;
        border: none;
        .online{
            background-color: $primary-color !important;
        }

        .dropdown-item {
            padding: 0.45rem 1rem;
            border-bottom: 1px solid #f1f1f1;
        }
    }
    .team-name{
        font-weight: 700;
        font-size: 1rem;
        color: $dark-gray;
    }
}