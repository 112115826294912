.space-settings{
    select {
        padding: 5px 35px 5px 20px;
        width: 100%;
        font-size: 1rem;
        border: none;
        height: 42px;
        border-radius: 50px;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        background: url('../../images/select-down-white.svg') no-repeat lighten($black, 18);
        background-position: right 20px center;
        background-size: 1rem;
        color: $white;
        outline: 0;
        &.page-count{
            width: 85px;
        }
      }

      .form-label{
          text-transform: uppercase;
      }
      .title-section{
        padding-top: 10px;
        .btn{
          float: none;
        }
      }

      .default-channels{
        border: 2px solid darken($white, 20);
        background-color: darken($white, 5);
        border-radius: 6px;
        box-shadow: none;
        border-radius: 6px;
        padding: 5px 0;
        display: flex;
        width: 100%;
        height: auto;
        flex-wrap: wrap;
        div{
            background-color: darken($white, 50);
            color: $white;
            padding: 10px 25px;
            border-radius: 25px;
            margin: 5px;
        }
      }

      .manage-users-table{
          padding: 5px 35px;
          margin-top: -105px;


          
        .manage-area{
          background-color: $white;
          padding: 15px 10px;
          border-bottom-left-radius: 10px;
          border-bottom-right-radius: 10px;
          max-height: calc(100vh - 186px);
          position: relative;

          h3{
              font-size: 1rem;
              color: lighten($black, 30);
          }

          p{
            font-size: 0.8rem;
            color: lighten($black, 30);
          }

          .invite-people{
            float: right;
            width: 140px;
          }
          .scroll-area{
            max-height: calc(100vh - 235px);
            overflow-y: hidden;
            position: relative;
          }
        }
        .space-header{
            background-color: lighten($black, 25);
            border-top-left-radius: 10px;
            border-top-right-radius: 10px;
            position: relative;
            font-weight: 700;
            color: $white;
            padding: 15px 15px;
            text-transform: uppercase;
        }

        .select-container > [class*="control"]{
            min-height: 48px;
            border: 2px solid darken($white, 20);
            border-radius: 0;
            box-shadow: none;
        }
        .line-sep{
            height: 1px;
            width: 100%;
            background-color: darken($white, 20);
            margin: 30px 0 15px;
        }
        textarea{
            &.form-control{
                border: 2px solid darken($white, 20);
                background-color: darken($white, 5);
                border-radius: 6px;
                box-shadow: none;
                &:focus{
                    border-color: $primary-color;
                }
            }
        }
        .save-change{
            padding: 10px 30px;
            margin: 20px auto;
            border-radius: 4px;
        }
    }
    .retention{
        label{
            font-weight: 700;
            display: block;
            margin-bottom: 0;
            font-size: 18px;
        }
        .days-count{
            width: 100px;
            display: inline-block;
            min-height: 48px;
            border: 2px solid darken($white, 20);
            border-radius: 0;
            box-shadow: none;
			text-align: center;
        }
        .days-count-mretention{
            width: 60px;
            display: inline-block;
            min-height: 48px;
            border: 2px solid darken($white, 20);
            border-radius: 0;
            box-shadow: none;
			text-align: center;
        }
        .days-type{
            width: 140px;
            margin-left: 15px;
            display: inline-block;
        }
        .btn-blue{
            border-radius: 6px;
            margin: 30px auto;
        }
        .retention-message{
            background: $light-gray;
            padding: 15px;
            border-radius: 6px;
            border: 2px solid lighten($gray, 50);
            color: $black;
            #workspaceoverride-message{
                margin-left: 20px;
            }
        }
        .form-control{
            border-radius: 0;
            border:  2px solid darken($white, 20);
            height: 48px;
            &.spacename-title{
                width: 50%;
                display: inline-block;
            }
        }
        .spacenameboomea{
            color: $primary-color;
            display: inline-block;
            margin: 0 5px;
        }
    }

}