
@font-face {
    font-family: 'open_sans';
    src: url('./fonts/OpenSans-Bold-webfont.eot');
    src: url('./fonts/OpenSans-Bold-webfont.eot?#iefix') format('embedded-opentype'),
         url('./fonts/OpenSans-Bold-webfont.woff') format('woff'),
         url('./fonts/OpenSans-Bold-webfont.ttf') format('truetype'),
         url('./fonts/OpenSans-Bold-webfont.svg#open_sansbold') format('svg');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: 'open_sans';
    src: url('./fonts/OpenSans-Regular-webfont.eot');
    src: url('./fonts/OpenSans-Regular-webfont.eot?#iefix') format('embedded-opentype'),
         url('./fonts/OpenSans-Regular-webfont.woff') format('woff'),
         url('./fonts/OpenSans-Regular-webfont.ttf') format('truetype'),
         url('./fonts/OpenSans-Regular-webfont.svg#open_sansregular') format('svg');
    font-weight: 400;
    font-style: normal;

}


body, html {
    margin: 0;
    padding: 0;
    font-family: 'open_sans';
    overflow-x: hidden;
    background-color: darken($white, 10);
    font-size: 0.95rem;
    height: 100%;
    color: lighten($black, 45);
    min-width: 1366px;
}

.footer{
    position: absolute;
    width: 100%;
    bottom: 0;
    text-align: center;
}
a{
  color: $primary-color;
}

.title-section{
    background-color: $primary-color;
    padding: 15px 30px;
    min-height: 155px;
    position: relative;
    .btn{
        float: right;
    }
    .row{
        display: flex;
        align-items: center;
    }
    h2{
        font-weight: 700;
        display: flex;
        align-items: center;
        font-size: 1.2rem;
        color: $white;
        text-transform: uppercase;
        position: absolute;
        top:20px;
        img{
            width: 20px;
            margin-right: 15px;
        }
        
    }
}

.btn{
    padding: 6px 20px;
    border-radius: 50px;
    box-shadow: none;
    font-weight: 700;
    display: flex;
    align-items: center;
    &.btn-default{
        background-color: $default;
        color: $white;
        &:hover{
          background-color: lighten($default, 10);
        }
        img{
            width: 10px;
            margin-right: 10px;
        }

        &.bordered{
          background-color: transparent;
          border: 2px solid $default;
          color: $default;
        }
    }

    &.btn-blue{
        background-color: $primary-color;
        color: $white;
        &:hover{
          background-color: $primary-color--hover;
        }
        &.bordered{
          background-color: transparent;
          border: 2px solid $primary-color;
          color: $primary-color;
        }
    }
    &.btn-green{
      background-color: $green;
      color: $white;
      &:hover{
        background-color: lighten($green, 10);
      }
  }
}




.checkbox-container {
    position: relative;
    margin-bottom: 12px;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  
  .checkbox-container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }
  
  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 18px;
    width: 18px;
    background-color: darken($white, 10);
    border: 1px solid  darken($white, 20);
    border-color: #e6e6e6;
    border-radius: 26px;
  }
  
  .checkbox-container:hover input ~ .checkmark {
    background-color: $primary-color--hover;
    border: 1px solid $primary-color;
  }
  
  .checkbox-container input:checked ~ .checkmark {
    background-color: transparent;
    border: 1px solid $primary-color;
  }
  

  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }
  
  /* Show the checkmark when checked */
  .checkbox-container input:checked ~ .checkmark:after {
    display: block;
  }
  
  /* Style the checkmark/indicator */
  .checkbox-container .checkmark:after {
    left: -1px;
    top: -1px;
    width: 18px;
    height: 18px;
    background-color: $primary-color;
    border-radius: 20px;
    // border: solid white;
    // border-width: 0 3px 3px 0;
    // -webkit-transform: rotate(45deg);
    // -ms-transform: rotate(45deg);
    // transform: rotate(45deg);
  }

  .custom-control-input:checked ~ .custom-control-label::before{
    border-color: $primary-color;
    background-color:  $primary-color;
  }

  .hide-side-header{
    #header, #sidebar{
      display: none;
    }
    #dashboard{
      padding: 0; 
    }
  }