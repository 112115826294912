.add-users{
    select {
        padding: 5px 35px 5px 20px;
        width: 100%;
        font-size: 1rem;
        border: none;
        height: 42px;
        border-radius: 50px;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        background: url('../../images/select-down-white.svg') no-repeat lighten($black, 18);
        background-position: right 20px center;
        background-size: 1rem;
        color: $white;
        outline: 0;
        &.page-count{
            width: 85px;
        }
      }
      input[type='search']{
        padding: 5px 35px 5px 20px;
        width: 100%;
        font-size: 1rem;
        border: none;
        height: 42px;
        border-radius: 50px;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        background: url('../../images/search.svg') no-repeat lighten($black, 18);
        background-position: right 20px center;
        background-size: 1rem;
        color: $white;
        outline: 0;
      }
      .add-user-form{
          padding: 15px 30px;
          margin-top: -110px;
          .form-body{
              background-color: $white;
              padding: 2.5rem;
              border-top-left-radius: 10px;
              border-top-right-radius: 10px;
              .form-group{
                  margin-bottom: 25px;
                  label{
                    margin-bottom: 0.4rem;
                    span{
                        color: $red;
                    }
                 }

                 .select-container > [class*="control"]{
                    min-height: 48px;
                    border: 2px solid darken($white, 20);
                    border-radius: 10px;
                    box-shadow: none;
                }

                 .form-control{
                    height: 48px;
                    border: 2px solid darken($white, 20);
                    border-radius: 10px;
                    &:focus{
                        box-shadow: none;
                        border-color: $primary-color;
                    }

                 }
              }
          }
          .form-footer{
              background-color: darken($white, 20);
              padding: 25px 25px 30px;
              text-align: center;
              border-bottom-left-radius: 10px;
              border-bottom-right-radius: 10px;
              .pull-right{
                  float: right;
              }
              .btn-default{
                  border: 2px solid $default;
                  &:hover{
                      background-color: transparent;
                      color: $default;
                  }
                  &.bordered{
                    &:hover{
                        background-color: $default;
                        color: $white;
                    }
                  }
              }
          }
      }
}

.Select-menu {
    position: fixed !important;
}