#sidebar{
    width: 240px;
    height: calc(100vh - 59px);
    background: $sidebar-bg;
    position: fixed;
    top: 59px;
    bottom: 0;
    left: 0;
    z-index: 9999;
    //overflow-y: auto;
    ul{
        list-style-type: none;
        padding: 0;
        width: 100%;
        li{
            a, label {
                display: flex;
                align-items: center;
                // background-color: darken($primary-color, 40);
                // border-bottom: 1px solid $sidbar-active;
                padding: 0;

                &:hover, &.active{
                    background-color: $primary-color;
                    text-decoration: none !important;
                    // .icon{
                    //     background-color: $primary-color;
                    // }
                }
               .icon{
                   display: inline-block;
                  //  background: #0a3143;
                   padding: 15px 10px;
                   min-width: 60px;
                   height: 60px;
                   text-align: center;
                   img{
                       width: 18px;
                   }
               }
               .descr{
                   text-transform: uppercase;
                   font-weight: 700;
                   color: $white;
                   display: inline-block;
                   padding-left: 10px;
                   font-size: 0.9rem
                }
            }

        }
    }
}

.small-sidebar{
    #sidebar{
        width: 60px;
        .nav__list {
            > li{
                a, label {
                   .descr{
                        display: none;
                    }
                }
                ul{
                  li{
                    a, label {
                        .descr{
                            display: block;
                        }
                    }
                  }
                }
            }
        }
        .nav__list li > label{
          pointer-events: none;
        }
        .nav__list input[type=checkbox]:checked + label + ul {
          max-height: 0;
          position: absolute;
        }
        .nav__list li{
          position: relative;
        &:hover{
          > ul, input[type=checkbox]:checked + label + ul {
            position: absolute;
            left: 100%;
            top:0;
            width: 180px;
            display: table
          }
        }
      }
    }
    #dashboard {
      .warapper {
          margin-left: 45px;
          padding: 0 15px;
          width: calc(100% - 20px);
        }
    }

}


.nav {
    a, label {
      display: block;
      padding: .85rem;
      color:$white;
      background-color: $sidebar-bg;
      -webkit-transition: all .25s ease-in;
      transition: all .25s ease-in;
    }
    a {
      &:focus, &:hover {
        color: rgba(255, 255, 255, 0.5);
        background: #030303;
      }
    }
    label {
      &:focus, &:hover {
        color: rgba(255, 255, 255, 0.5);
        background: #030303;
      }
      cursor: pointer;
      margin-bottom: 0;
    }
  }

  .group-list {
    a, label {
      padding-left: 2rem;
      background: #13213a !important;
      padding: 20px 20px 20px 60px !important;
    }
    a {
      &:focus, &:hover {
        color: #131313;
      }
      &.active{
        background-color: $primary-color !important;
      }
    }
    label {
      &:focus, &:hover {
        background: #131313;
      }
    }
  }

  .sub-group-list {
    a, label {
      padding-left: 4rem;
      background: #353535;
    }
    a {
      &:focus, &:hover {
        background: #232323;
      }
    }
    label {
      &:focus, &:hover {
        background: #232323;
      }
    }
  }

  .sub-sub-group-list {
    a, label {
      padding-left: 6rem;
      background: #454545;
    }
    a {
      &:focus, &:hover {
        background: #333333;
      }
    }
    label {
      &:focus, &:hover {
        background: #333333;
      }
    }
  }

  .group-list, .sub-group-list, .sub-sub-group-list {
    height: 100%;
    max-height: 0;
    overflow: hidden;
    -webkit-transition: max-height .5s ease-in-out;
    transition: max-height .5s ease-in-out;
  }

  // .nav__list input[type=checkbox]:checked + label{
  //   background-color: $sidbar-active;
  //   text-decoration: none !important;
  //   .icon{
  //       background-color: lighten($sidbar-active, 5);
  //   }
  // }
  .nav__list input[type=radio]:checked + label + ul {
    max-height: 1000px;
  }
  .nav__list input[type=radio]:checked + label {
    background-color: $secondary-blue
  }




  label > span {
    float: right;
    -webkit-transition: -webkit-transform .65s ease;
    transition: transform .65s ease;
  }

  .nav__list input[type=radio]:checked + label > span {
    -webkit-transform: rotate(90deg);
    -ms-transform: rotate(90deg);
    transform: rotate(90deg);
  }
