#manage-users{
    select {
      padding: 5px 35px 5px 20px;
      width: 100%;
      font-size: 1rem;
      border: none;
      height: 42px;
      border-radius: 50px;
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      background: url('../../images/select-down-white.svg') no-repeat lighten($black, 18);
      background-position: right 20px center;
      background-size: 1rem;
      color: $white;
      outline: 0;
      &.page-count{
          width: 85px;
      }
    }
    input[type='search']{
      padding: 5px 35px 5px 20px;
      width: 100%;
      font-size: 1rem;
      border: none;
      height: 36px;
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      background: url('../../images/search-grey.svg') no-repeat $white;
      background-position: right 15px center;
      background-size: 1rem;
      color: $dark-gray;
      outline: 0;
      border: 2px solid darken($white, 10);
    }
    #search-comp-dir{
      padding: 5px 35px 5px 20px;
      width: 100%;
      font-size: 1rem;
      border: none;
      height: 36px;
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      background: url('../../images/search-grey.svg') no-repeat $white;
      background-position: right 15px center;
      background-size: 1rem;
      color: $dark-gray;
      outline: 0;
      border: 2px solid darken($white, 10);
    }
    .title-section{
      padding-top: 10px;
      .btn{
        float: none;
      }
      .download-button, .invite-people{
        border-radius: 4px;
        padding: 6px 12px;
        text-transform: uppercase;
        height: 36px;
        img{
          width: 1rem;
          margin-right: 5px;
        }
      }
      .download-button{
        img{
          margin-right: 0;
        }
      }
      .list-inline{
        display: flex;
        float: right;
        li{
          margin-left: 5px;
        }
      }
    }

    .manage-users-table{
        padding: 5px 35px;
        margin-top: -105px;
      .manage-area{
        background-color: $white;
        padding: 15px 10px;
        border-radius: 10px;
        height: calc(100vh - 135px);
        position: relative;

        .invite-people{
          float: right;
          width: 140px;
        }

        .search-area-form{
          display: flex;
          margin-bottom: 5px;
          .serach-input{
            input{
              border-radius: 0;
              box-shadow: none;
              &:focus{
                border-color: $primary-color;
              }
            }
            width:100%;
          }
          .clearable__clear_icon{
            position: absolute;
            right: 210px;
            top: -5px;
            padding: 0 8px;
            font-style: normal;
            font-size: 2.2em;
            color: $dark-gray;
            user-select: none;
            cursor: pointer;
            outline: none;
          }
		  .clearable__clear{
            position: absolute;
            right: 40px;
            top: -5px;
            padding: 0 8px;
            font-style: normal;
            font-size: 2.2em;
            color: $dark-gray;
            user-select: none;
            cursor: pointer;
            outline: none;
          }
        }
        .scroll-area{
          height: calc(100vh - 235px);
          overflow-y: scroll;
          position: relative;
        }
        .table{
          margin-bottom: 0.3rem;
          tbody{
            tr{
              &:first-child{
                td{
                  border-top: 5px solid $primary-color;
                }
              }
              border-bottom: 1px solid darken($white, 10);
            }
          }
          tr{  
              background-color: $white;
              &.active-row{
                  background-color: lighten($sidebar-bg, 88)
              }
              th{
                  background-color: lighten($black, 95);
                  padding: 0.8rem;
                  text-align: left;
                  border:none;
                  color: $black;
                  position: sticky;
                  position: -webkit-sticky;
                  top: 0;
                  z-index: 999;
              }
              td{
                  text-align: left;
                  background-color: $white;
                  padding: 0.8rem;
                  vertical-align: middle;
                  background-color: transparent;
                  .media{
                    text-align: left;
                    h5{
                      font-weight: 700;
                      color: $primary-color;
                      margin-top: 13px;
                      margin-bottom: 0;
                      font-size: 1rem;
                    }
                    p{
                      margin-bottom: 0;
                    }
                  }
                  select{
                      border-radius: 10px;
                  }
                  .account-type{
                    display: flex;
                    img{
                      width: 1rem;
                      margin-right: 10px;
                    }
                  }
                  &.dots-menu{
                    text-align: center;
                    > div {
                      display: inline-block;
                    }
                    .btn{
                      padding: 0;
                      outline: 0;
                      display: inline-block;
                      &::before{
                        display: none;
                      }
                    }
                  }
              }
          }
      }
    }
  }
  .dropdown-menu{
    box-shadow: 0 0 20px darken($white, 10);
    top: 0 !important;
  }
}

.add-cduser{
  .modal-content{
    background-color: transparent;
  }
  .modal-header{
    background-color: $primary-color;
    .h4{
      font-size: 1rem;
      font-weight: 700;
      color: $white;
      
    }
  }
  .modal-body, .modal-footer{
    background-color: $white;
    padding: 20px 25px;
  }
  .modal-footer{
    .btn{
      width: 100px;
      justify-content: center;
    }
  }
  .form-control{
    height: 42px;
    border: 2px solid darken($white, 20);
    box-shadow: none;
    &:focus{
      border-color: $primary-color;
    }
  }
  span{
    color: $red;
    float: left;
  }
}