 .switch-team {
     .modal-body {
         padding: 10px 0 0 0;
        .filtered-user-list {
            display: flex;
            flex-direction: column;
            height: auto;
            width: 100% !important;
            .more-modal__list {
                height: auto;
                overflow: auto;
                display: flex;
                flex-direction: column;
                .more-modal__row {
                    border-bottom: 1px solid rgba(0, 0, 0, 0.15);
                    display: flex;
                    padding: 10px 15px;
                    &.active {
                        color: #ffffff;
                        background-color: #599DC1;
                    }
                    &:hover:not(.active) {
                        background-color: lightgrey;
                        cursor: pointer;
                    }
                }
            }
        }
    }
    .modal-footer {
        border-top: 0px;
    }
}