.billing{
    select {
        padding: 5px 35px 5px 20px;
        width: 100%;
        font-size: 1rem;
        border: none;
        height: 42px;
        border-radius: 50px;
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        background: url('../../images/select-down-white.svg') no-repeat lighten($black, 18);
        background-position: right 20px center;
        background-size: 1rem;
        color: $white;
        outline: 0;
        &.page-count{
            width: 85px;
        }
      }

      .title-section{
        padding-top: 10px;
        .btn{
          float: none;
        }
      }

      .manage-users-table{
          padding: 5px 35px;
          margin-top: -105px;
            &.billing-method{
                width: 768px;
                margin: -105px auto 0;
                .btn{
                    border-radius: 6px;
                    padding: 10px 25px;
                }
                form{
                    padding: 15px 0;
                    border-top: 1px solid darken($white, 10);
                    margin-top: 10px;
                    width: 100%;

                    .card-expiry-table{
                        td{
                            vertical-align: middle;
                            img{
                                margin-top: 10px;
                            }
                        }
                        input{
                            width: calc(100% - 15px);
                            text-align: center;
                        }
                    }
                }

                .manage-billing-area{
                  background-color: $white;
                  padding: 15px 10px 30px;
                  border-bottom-left-radius: 10px;
                  border-bottom-right-radius: 10px;
                  position: relative;
                    h3{
                        font-size: 1rem;
                        color: lighten($black, 30);
                    }

                    p{
                      font-size: 0.8rem;
                      color: lighten($black, 30);
                    }
                }
            }
        .billing-header{
            background-color: lighten($black, 25);
            border-top-left-radius: 10px;
            border-top-right-radius: 10px;
            position: relative;
            font-weight: 700;
            color: $white;
            padding: 15px 15px;
            text-transform: uppercase;
        }
          
        .manage-area{
          background-color: $white;
          padding: 15px 10px;
          border-bottom-left-radius: 10px;
          border-bottom-right-radius: 10px;

          position: relative;

          h3{
              font-size: 1rem;
              color: lighten($black, 30);
          }

          p{
            font-size: 0.8rem;
            color: lighten($black, 30);
          }

          .invite-people{
            float: right;
            width: 140px;
          }
          .scroll-area{
            height: calc(100vh - 235px);
            overflow-y: hidden;
            position: relative;
          }
          .form-body{
            .form-group{
                margin-bottom: 15px;
                label{
                  margin-bottom: 0.4rem;
                  font-size: 0.8rem;
                  span{
                      color: $red;
                  }
               }

               .select-container > [class*="control"]{
                  min-height: 40px;
                  border: 2px solid darken($white, 20);
                  border-radius: 0;
                  box-shadow: none;
              }

               .form-control{
                  height: 40px;
                  border: 2px solid darken($white, 20);
                  border-radius: 0;
                  &:focus{
                      box-shadow: none;
                      border-color: $primary-color;
                  }
                  &.text-area{
                      height: auto;
                  }
               }
                 p {
                    color: $gray;
                    margin-top: 0.4rem;
                }
            }
        }
        .form-footer{
            background-color: $white;
            padding: 25px 0 10px;
            text-align: center;
            border-bottom-left-radius: 10px;
            border-bottom-right-radius: 10px;
            text-align: right;
            .pull-right{
                float: right;
            }
            .btn-blue{
                border: 2px solid $primary-color;
                background-color: $primary-color;
                border-radius: 6px;
                padding: 10px 25px;
                float: right;

                &:hover{
                    background-color: $primary-color--hover;
                    color: $white;
                }
            }
        }
        &.activity-log, &.billing-history{
            padding: 10px;
            .daterange-table{
                tbody{
                    tr{
                      &.no-border{
                          td{
                              border: none;
                              padding: 0 0.4rem;
                              .date-picker{
                                  background: url('../../images/calander.svg') no-repeat $white;
                                  background-position: right 10px center;
                                  background-size: 1rem;
                                  width: 150px;
                              }
                              .btn{
                                  border-radius: 4px;
                              }
                           }
                        }

                     }
                  }
                  margin-bottom: 10px;
            }
            .table{
            margin-bottom: 0.3rem;
            tbody{
              tr{
                &:first-child{
                  td{
                    border-top: 5px solid $primary-color;
                  }
                }
                border-bottom: 1px solid darken($white, 10);
                &.no-border{
                    td{
                        border: none;
                        padding: 0.4rem;
                        .date-picker{
                            background: url('../../images/calander.svg') no-repeat $white;
                            background-position: right 10px center;
                            background-size: 1rem;
                            width: 135px;
                        }
                     }
                  }
               }
            }
            tr{  
                background-color: $white;
                &.active-row{
                    background-color: lighten($sidebar-bg, 88)
                }
                &.activity-list{
                    a{
                        text-decoration: none;
                    }
                    span{
                        color: $gray;
                    }
                    .list-avatar{
                        margin-right: 8px;
                    }
                }
                th{
                    background-color: lighten($black, 90);
                    padding: 0.8rem;
                    text-align: left;
                    border:none;
                    color: $black;
                }
                td{
                    text-align: left;
                    background-color: $white;
                    padding: 0.8rem;
                    vertical-align: middle;
                    background-color: transparent;

                    .media{
                      text-align: left;
                      h5{
                        font-weight: 700;
                        color: $primary-color;
                        margin-top: 2px;
                        margin-bottom: 0;
                        font-size: 1rem;
                      }
                      p{
                        margin-bottom: 0;
                      }
                    }
                    select{
                        border-radius: 10px;
                    }
                    .account-type{
                      display: flex;
                      img{
                        width: 1rem;
                        margin-right: 10px;
                      }
                    }
                    &.dots-menu{
                      .btn{
                        padding: 0;
                        outline: 0;
                        &::before{
                          display: none;
                        }
                      }
                    }
                }
            }
          }
          input[type='search']{
            padding: 5px 35px 5px 10px;
            width: 100%;
            font-size: 1rem;
            border: none;
            height: 36px;
            background: url('../../images/search-grey.svg') no-repeat $white;
            background-position: right 15px center;
            background-size: 1rem;
            color: $dark-gray !important;
            outline: 0;
            border: 2px solid darken($white, 10);
            box-shadow: none;
            &:focus{
                border-color: $primary-color;
            }
          }

          .scroll-area{
            height: calc(100vh - 285px);
            overflow-y: hidden;
            position: relative;
          }
          .download-invoice{
              border-radius: 6px;
              float: right;
              text-transform: uppercase;
              img{
                  width: 16px;
                  margin-right: 5px;
              }
          }
          small{
              display: block;
          }
          .billing-history-table{
            tr{
                td, th{
                    text-align: center;
                    &:last-child{
                        width: 120px;
                    }
                    &.text-center{
                        text-align: center;
                    }
                }
             }
           }
        }
      }
      &.change-plan{
          .manage-area {
            p {
                font-size: 1rem !important;
            }
            .list-styled{
                list-style-type: none;
                li{
                    margin-bottom:5px;
                    .btn-blue{
                      display: table;
                      border-radius: 4px;
                      margin-top: 10px;
                    }
                }
            }
         }
         .border-box{
             border: 2px solid $primary-color;
             border-radius: 10px;
             padding: 15px;
             margin: 25px 0;
             h4{
                 margin-bottom: 5px;
                 font-size: 18px;
             }

             ul {
                list-style: none;
                padding: 5px 15px;
                margin-bottom: 0;
               li::before {
                content: "\2022";
                color: $primary-color;
                font-weight: bold;
                display: inline-block; 
                width: 1rem;
                margin-left: -1em;
                margin-bottom: 5px;
              }
            }
         }

         .change-button{
            border-radius: 4px;
            margin: auto;
            padding: 10px 35px;
        }
        p{
            line-height: 1.8rem
        }
      }


    }
   .dropdown-menu{
     box-shadow: 0 0 20px darken($white, 10);
     top: 0 !important;
   }

   .boomea-plans{
     .row{
       margin-right: 0;
       margin-left: 8px;
       .col-3{
         padding: 0;
         margin-left: -2px;
       }
     }
    .plan-section{
      text-align: center;
      background-color: darken($white, 2);
      border: 2px solid darken($white, 15);
      position: relative;
      z-index: 1;
      .plan-header{
        padding: 20px;
        h4{
          font-size: 1.6rem;
          font-weight: 700;
          margin: 10px 0 20px;
          color: $black;
          text-transform: uppercase
        }
        p{
          font-size: 1rem;
        }
        .plan-price{
          margin-top: 40px;
          font-size: 2.4rem;
          font-weight: 700;
          color: $primary-color;
        }
        .btn{
          font-size: 1.2rem;
          padding: 10px 40px;
          border-radius: 8px;
          margin: 15px auto;
        }
      }

      .plan-footer{
        padding: 20px;
        .plan-contains{
          ul{
            list-style-type: none;
            text-align: left;
            padding: 0;
            li{
              color: $black;
              font-size: 1rem;
              display: flex;
              align-items: center;
              justify-content: left;
              padding: 10px 0;
              border-bottom: 1px solid darken($white, 5);
              &:before {
                content: " "; 
                background:  url('../../images/boomea-blue.svg') no-repeat;
                display: inline-flex;
                margin-right: 10px;;
                width: 1rem; 
                height: 1rem
              }
            }
          }
        }
      }
      &:hover{
        background-color: $white;
        border: 2px solid $primary-color;
        z-index: 2;
        h4{
          color: $primary-color--hover;
        }
      }
    }
  }
}