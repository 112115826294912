@charset 'UTF-8';



// Color Variables
$primary-color: rgb(89, 154, 209);
$primary-color--hover: darken($primary-color, 10%);
$bg--gray: #e5e5e5;
$white: rgb(255, 255, 255);
$black: rgb(0, 0, 0);
$red:  #ff413c;
$yellow: rgb(255, 255, 0);
$green: #026f50;
$light-gray: rgba(0, 0, 0, .15);
$gray: rgba(0, 0, 0, .3);
$dark-gray: rgba(0, 0, 0, 0.6);
$link-color: rgb(35, 137, 215);
$secondary-blue: #273a60;

$sidebar-bg: #021c26;
$sidbar-active: #042c38;
$default: #115576;
$task-notification: #202642;
// Page Variables
$border-gray: 1px solid #ddd;
$light-bg: rgba(0, 0, 0, .03);

// Random variables
$border-rad: 1px;

// Webrtc Variables
$button--ready: #00A6EE;
$video-circle-btn: #2f81b7;
$video-circle-hover: #56C303;
$video-circle-offline: #aaa;
$button--cancel: #E41020;
$button--pickup: #73B001;
$connecting: $white;
$webrtc-notification-bg: rgba(112, 128, 144, .8);


$bold: 700;



// Responsive Break Points
$break-xs: 600px;
$break-sm: 990px;
$break-md: 1025px;
